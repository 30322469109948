<template>
  <div>
    <v-layout align-center>
      <v-autocomplete
        :search-input.sync="search"
        :value="value"
        :items="filteredPointsOrder"
        clearable
        :loading="isLoading"
        :item-text="itemText"
        item-value="id"
        :label="label"
        return-object
        :multiple="multiple"
        chips
        @change="emitValue"
        :hide-no-data="searched"
      />
    </v-layout>
  </div>
</template>

<script>
import { getPointsOrderByParName } from "@/services/points-order-service";
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: [Array, Object],
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dialog: false,
    filteredPointsOrder: [],
    search: "",
    loading: false,
  }),
  computed: {
    searched() {
      return this.search && this.search.length === 0;
    },
    isLoading() {
      return this.$store.getters["loading/isLoading"];
    },
  },
  watch: {
    search(value) {
      if (value && value.length > 0) {
        this.filter(value);
      }
    },
    value(value) {
      if (value) {
        this.filteredPointsOrder = this.value;
      }
    },
  },
  created() {
    if (this.value) {
      this.filteredPointsOrder = this.value;
    }
  },
  methods: {
    async filter(value) {
      try {
        let response = await getPointsOrderByParName(value);
        this.filteredPointsOrder = this.filteredPointsOrder.concat(
          response.data
        );
      } catch (error) {
        this.$toasted.global.defaultError();
      }
    },
    emitValue(value) {
      this.filteredPointsOrder = value;
      this.$emit("input", value);
    },
    itemText(pointOrder) {
      return `${pointOrder.par?.name} (${pointOrder.par?.type?.name})`;
    },
  },
};
</script>

<style></style>
