import api from "./api-service";

const url = "points-orders";

export const loadPoints = ({ parId, typeId }) => {
  return api().get(`${url}/load-points/par/${parId}/type/${typeId}`);
};

export const saveMany = (array) => {
  return api().post(`${url}/save-many`, array);
};

export const getPointsOrderByParName = (name) => {
  return api().get(`${url}/par/name/${name}`);
};
